// src/i18n/index.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "welcome": "Welcome to my website!",
                    "about_me": "About Me",
                    "projects": "Projects",
                    "experience": "Professional Experience",
                    "contact": "Contact",
                    "menuscan_desc": "An innovative application for scanning and browsing restaurant menus.",
                    "crv_desc": "A warehouse management application for entering customers and generating pdfs.",
                    "fullstack_developer": "Fullstack Developer",
                    "description": "I'm a passionate full-stack developer with over two years of experience in crafting WPF and Yii2 applications. I specialize in Flutter, React, WPF, and Yii2.",
                    "about_description": "As a dedicated developer, I thrive on creating modern web and mobile applications. My journey in technology allows me to blend creativity with technical expertise, delivering exceptional digital solutions. In my free time, I enjoy playing sports like football, basketball, and volleyball.",
                    "mssoft_desc": "At MS-Soft, I focused on developing and enhancing WPF and Yii2 applications, contributing to various innovative projects.",
                    "crosswave_desc": "At Cross-Wave, my own brand, I work as a full-stack developer, creating applications using Yii2, Flutter, WPF, and React.",
                }
            },
            pl: {
                translation: {
                    "welcome": "Witaj na mojej stronie!",
                    "about_me": "O mnie",
                    "projects": "Projekty",
                    "experience": "Doświadczenie zawodowe",
                    "contact": "Kontakt",
                    "menuscan_desc": "Innowacyjna aplikacja do skanowania i przeglądania menu restauracji.",
                    "crv_desc": "Aplikacja do zarządzania magazynem, wprowadzania klientów i generowaniem pdf-ów.",
                    "fullstack_developer": "Fullstack Developer",
                    "description": "Jestem pasjonatem programowania z ponad dwuletnim doświadczeniem w tworzeniu aplikacji WPF i Yii2. Specjalizuję się w Flutter, React, WPF i Yii2.",
                    "about_description": "Jako zaangażowany programista, uwielbiam tworzyć nowoczesne aplikacje webowe i mobilne. Moja podróż w świecie technologii pozwala mi łączyć kreatywność z wiedzą techniczną, dostarczając wyjątkowe rozwiązania cyfrowe. W wolnym czasie lubię uprawiać sporty, takie jak piłka nożna, koszykówka i siatkówka.",
                    "mssoft_desc": "W MS-Soft skupiałem się na rozwoju i udoskonalaniu aplikacji WPF i Yii2, przyczyniając się do różnych innowacyjnych projektów.",
                    "crosswave_desc": "W Cross-Wave, mojej własnej marce, pracuję jako fullstack developer, tworząc aplikacje z wykorzystaniem Yii2, Flutter, WPF i React.",
                }
            },
        },
        fallbackLng: 'pl',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
